import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";

import { Row, Container, Col } from "react-bootstrap";

import Layout from "../components/common/Layout";
import Pagination from "../components/common/Pagination";
import Anchor from "../components/elements/Anchor";
import { ArrowDownBig } from "../helpers/Icons";

import "../styles/components/elements/card.scss";

const Chefs = ({ data, pageContext }) => {
  if (!data) return null;

  const {
    title,
    description,
    show_description: showDescription,
  } = data.prismicAllChefs.data;

  const chefs = data.allPrismicChef?.nodes.map((item) => {
    const { url, data: chef } = item;

    return (
      <Col md={6} key={url}>
        <Anchor
          href={url}
          className="card-chef d-block position-relative mb-3 mb-md-4 bg-light overflow-hidden"
        >
          <GatsbyImage
            image={chef.image.gatsbyImageData}
            alt={chef.image.alt || chef.name || ""}
            className="card-image-animation"
          />
          <h2 className="h4 position-absolute bottom-0 left-0 mb-5 me-5 p-3 bg-white">
            {chef.name}
          </h2>
        </Anchor>
      </Col>
    );
  });

  return (
    <Layout>
      <Container>
        <div className="pt-5 pb-6 text-center">
          <h1 className="h4">{title}</h1>
          {showDescription && <RichText render={description.richText} />}
          <ArrowDownBig size={58} />
        </div>
        <Row>{chefs}</Row>
        <Pagination
          pageInfo={data.allPrismicChef.pageInfo}
          base={pageContext.base}
        />
      </Container>
    </Layout>
  );
};

export const allChefsQuery = graphql`
  query allChefsQuery($limit: Int!, $skip: Int!) {
    prismicAllChefs {
      data {
        title
        description {
          richText
          text
        }
        show_description
      }
    }
    allPrismicChef(limit: $limit, skip: $skip) {
      nodes {
        ...prismicChefFragment
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
      }
    }
  }
`;

export default Chefs;
